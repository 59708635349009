.center-flex {
    display: flex;
    justify-content: center;
    border-radius: 5px; /* Optional: Adds rounded corners */
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    height: 25vw;
  }
  
  .typography {
    color: var(--text-secondary); /* You can define this CSS variable elsewhere */
    font-size: 16px;
    margin: 0;
    font-weight: bold;
    padding: 2px;
  }
  
  .slider {
    margin-left: 5px;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 93%;
  }
  